import './App.scss';
import Figure from "./components/Figure";
import InputWithD from "./components/InputWithD";
import { useState, useMemo } from "react";
import { MathComponent } from "mathjax-react";

function App() {

    const [showLines, setShowLines] = useState(true);
    const [lineStrength, setLineStrength] = useState(5);
    const [dotStrength, setDotStrength] = useState(60);
    const [dotSize, setDotSize] = useState(2);
    const [zoomLevel, setZoomLevel] = useState(20);
    const [showParams, setShowParams] = useState(false);
    const [showIterations, setShowIterations] = useState(false);

    const [exp, setExp] = useState(2);
    const [dExp, setDExp] = useState(0);
    const [reC, setReC] = useState(-0.1);
    const [dReC, setDReC] = useState(-0);
    const [imC, setImC] = useState(1);
    const [dImC, setDImC] = useState(0);
    const [algorithm, setAlgorithm] = useState('MA');

    const [n, setN] = useState(1000)
    const [dN, setDN] = useState(0)
    const [count, setCount] = useState(10)

    const [beta, setBeta] = useState(-0.0065);
    const [dBeta, setDBeta] = useState(0);
    const [g, setG] = useState(4);
    const [dG, setDG] = useState(0);
    const [v, setV] = useState(5);
    const [dV, setDV] = useState(0);
    const [lambda, setLambda] = useState(1.009);
    const [dLambda, setDLambda] = useState(0);

    const [updatedValues, setUpdatedValues] = useState({
        zoomLevel,
        showParams,
        showIterations,
        exp,
        showLines,
        lineStrength,
        dotStrength,
        dotSize,
        reC,
        imC,
        beta,
        g,
        v,
        lambda,
        dExp,
        dReC,
        dImC,
        dBeta,
        dG,
        dV,
        dLambda,
        n,
        dN,
        count,
      });
    
      const handleOptionChange = changeEvent => {
        setAlgorithm(changeEvent.target.value);
      };
    
      const handleUpdateFigures = () => {
        const updated = {
            zoomLevel,
            showParams,
            showIterations,
            exp,
            showLines,
            lineStrength,
            dotStrength,
            dotSize,
            reC,
            imC,
            beta,
            g,
            v,
            lambda,
            dExp,
            dReC,
            dImC,
            dBeta,
            dG,
            dV,
            dLambda,
            n,
            dN,
            count,
          };
        setUpdatedValues(updated);
      };    
  
    const figures = useMemo(() => {
        let currentExp = updatedValues.exp;
        let currentReC = updatedValues.reC;
        let currentImC = updatedValues.imC;
    
        let currentBeta = updatedValues.beta;
        let currentG = updatedValues.g;
        let currentV = updatedValues.v;
        let currentLambda = updatedValues.lambda;

        let currentN = updatedValues.n;

        const figuresArray: JSX.Element[] = [];
    
        for (let i = 0; i < count; i++) {
            figuresArray.push(
                <Figure
                    key={i}
                    showParams={updatedValues.showParams}
                    showIterations={updatedValues.showIterations}
                    zoomLevel={updatedValues.zoomLevel}
                    showLines={updatedValues.showLines}
                    lineStrength={updatedValues.lineStrength}
                    dotStrength={updatedValues.dotStrength}
                    dotSize={updatedValues.dotSize}
                    exp={currentExp}
                    algorithm={algorithm}
                    reC={currentReC}
                    imC={currentImC}
                    beta={currentBeta}
                    g={currentG}
                    v={currentV}
                    lambda={currentLambda}
                    n={currentN}
                />
            );
            currentExp += dExp;
            currentReC += dReC;
            currentImC += dImC;

            currentBeta += dBeta;
            currentG += dG;
            currentV += dV;
            currentLambda += dLambda;

            currentN += dN;
        }
        return figuresArray;
    }, [updatedValues]);

    return (
        <html>
        <head>
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css"
                  integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO"
                  crossOrigin="anonymous"/>
        </head>
        <div className="App">
            <div className="control-panel">


                <div className="input-group col-sm-12">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Zoom</span>
                    </div>
                    <input className="form-control"  type="range" min="1" max="200" step="0.1" defaultValue="20" onChange={(e) => setZoomLevel(parseFloat(e.target.value))} />
                </div>

                <div>
                    <input type="radio" name="alg"
                        checked={algorithm === "MA"}
                        onChange={handleOptionChange}
                        value="MA"
                    /><span>MA</span>
                </div>
                <div>
                    <input type="radio" name="alg" value="MB"
                        checked={algorithm === "MB"}
                        onChange={handleOptionChange}
                    /><span>MB</span>
                </div>
                <div>
                    <input type="radio" name="alg"
                        checked={algorithm === "MC"}
                        onChange={handleOptionChange}
                        value="MC"
                    /><span>MC</span>
                </div>
                <div>
                    <input type="radio" name="alg"
                        checked={algorithm === "MX"}
                        onChange={handleOptionChange}
                        value="MX"/><span>MX</span>
                </div>

                <InputWithD label="EXP" value={exp} dValue={dExp} onChange={setExp} onDChange={setDExp} />
                {algorithm !== 'MX' && (<div>
                    <InputWithD label="Re(C)" value={reC} dValue={dReC} onChange={setReC} onDChange={setDReC} />
                    <InputWithD label="Im(C)" value={imC} dValue={dImC} onChange={setImC} onDChange={setDImC} />
                </div>)}


                {algorithm === 'MX' && (<div>
                    <InputWithD label="&beta;" value={beta} dValue={dBeta} onChange={setBeta} onDChange={setDBeta} />
                    <InputWithD label="g" value={g} dValue={dG} onChange={setG} onDChange={setDG} />
                    <InputWithD label="v" value={v} dValue={dV} onChange={setV} onDChange={setDV} />
                    <InputWithD label="&lambda;" value={lambda} dValue={dLambda} onChange={setLambda} onDChange={setDLambda} />
                </div>)}

                <InputWithD label="n" value={n} dValue={dN} onChange={setN} onDChange={setDN} />

                <div className="input-group">
                    <div className="input-group-prepend">
                        <label className="btn btn-secondary">Count</label>
                    </div>
                    <input className="form-control" defaultValue={count} type="text" onChange={e => setCount(parseInt(e.target.value, 10))} />
                </div>

                <div className="input-group">
                    <div className="input-group-prepend">
                        <label className="btn btn-secondary">Dot size</label>
                    </div>
                    <input className="form-control" defaultValue={dotSize} type="text" onChange={e => setDotSize(parseInt(e.target.value, 10))} />
                </div>


                <div className="input-group  col-sm-12">
                    <div className="input-group-prepend">
                        <label className="btn btn-secondary">
                            Dot strength
                        </label>
                    </div>
                    <input className="form-control" type="range" min="0" max="80" step="0.1" defaultValue="60" onChange={(e) => setDotStrength(parseFloat(e.target.value))} />
                </div>                


                <div className="input-group  col-sm-12">
                    <div className="input-group-prepend">
                        <label className="btn btn-secondary">
                        <input type="checkbox" checked={showLines} onChange={(e) => setShowLines(e.target.checked)}/>
                            Line strength
                        </label>
                    </div>
                    <input disabled={!showLines} className="form-control" type="range" min="1" max="20" step="0.1" defaultValue="5" onChange={(e) => setLineStrength(parseFloat(e.target.value))}/>
                </div>

                <div className="input-group col-sm-12">
                    {/* <label className="btn btn-secondary" data-original-title="" title="">
                        <input type="checkbox" id="flow" name="flow" value="on" onChange={(e) => setShowIterations(e.target.checked)}/>
                        Show iteration flow
                    </label>
                    &nbsp;&nbsp; */}
                    <label className="btn btn-secondary" data-original-title="" title="">
                        <input type="checkbox" id="parameters" name="parameters"  onChange={(e) => setShowParams(e.target.checked)}/>
                        Show parameters
                    </label>
                </div>                


                <button onClick={handleUpdateFigures}>Update Figures</button>
            </div>
            <div className="figures-container">
                {figures}
            </div>
        </div>
        </html>
    );
}

export default App;
