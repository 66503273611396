// @ts-nocheck

import Figure from "./figure";

export type CanvasOptions = {
    devicePixelRatio?: number;
    paperScope: any;
    size: number;
    dotStrength: number;
    lineStrength: number;
    figure: Figure<any>;
    TOP?: number;
    LEFT?: number;
}


export default class Canvas {
    public options: CanvasOptions = { devicePixelRatio: 1, TOP: 0, LEFT: 0 };
    private dots: [number, number][];
    private origo: { x: number; y: number };
    private secondaryColor = '#ff5151';

    constructor(options: CanvasOptions) {
        this.options = {
            devicePixelRatio: 1,
            TOP: 0,
            LEFT: 0,
            ...options
        };

        this.dots = this.options.figure.calculateDotPositions();
        this.origo = this.options.figure.calculateDotPosition(0, 0);
    }

    addDots() {
            window.hello = {x:[],y:[],z:[]}
        this.dots.forEach(([x, y], key) => {
            if (Number.isNaN(x) || Number.isNaN(y)) {
                return;
            }
            const X = x / this.options.devicePixelRatio + this.options.LEFT;
            const Y = y / this.options.devicePixelRatio + this.options.TOP;
            window.hello.x.push(X);
            window.hello.y.push(Y);
            window.hello.z.push(key);
            const circle = new this.options.paperScope.Shape.Circle(new this.options.paperScope.Point(X, Y), this.options.size / 5);
            circle.strokeColor = `rgba(255,0,0,${(this.options.dotStrength / 100).toFixed(2)})`;
            circle.fillColor = `rgba(255,0,0,${(this.options.dotStrength / 100).toFixed(2)})`;
        });
    }

    addLines() {
        const previous = { x: this.origo.x, y: this.origo.y };

        this.dots.forEach(([x, y]) => {
            if (Number.isNaN(x) || Number.isNaN(y)) {
                return;
            }

            const start = new this.options.paperScope.Point(previous.x / this.options.devicePixelRatio + this.options.LEFT, previous.y / this.options.devicePixelRatio + this.options.TOP);
            previous.x = x;
            previous.y = y;
            const end = new this.options.paperScope.Point(x / this.options.devicePixelRatio + this.options.LEFT, y / this.options.devicePixelRatio + this.options.TOP);

            const newline = new this.options.paperScope.Path.Line(start, end);
            newline.strokeColor = `rgba(255,0,0,${this.options.lineStrength / 100})`;
            newline.strokeWidth = 1;
        });
    }

    // addText(content: string, justification: 'left' | 'right' | 'center', x, y) {
    //     const fontOptions = {
    //         justification,
    //         fontFamily: 'Arial',
    //         fontSize: 16,
    //         fillColor: '#000000',
    //     };
    //
    //     const text = new this.options.paperScope.PointText({
    //         point: new this.options.paperScope.Point(x, y),
    //         content,
    //         ...fontOptions,
    //     });
    //
    //     console.log('bounds', text.bounds);
    //     const rect = new this.options.paperScope.Path.Rectangle(text.bounds);
    //     // rect.fillColor = secondaryColor;
    //     rect.strokeColor = this.secondaryColor;
    //     text.insertAbove(rect);
    // }

    showAxis() {

        const x = Math.round(this.origo.x);
        const y = Math.round(this.origo.y);

        const reStart = new this.options.paperScope.Point(this.options.LEFT, y / this.options.devicePixelRatio + this.options.TOP);
        const reEnd = new this.options.paperScope.Point(this.options.LEFT + this.options.figure.width, y / this.options.devicePixelRatio + this.options.TOP);

        const reAxis = new this.options.paperScope.Path({ segments: [reStart, reEnd] });
        reAxis.strokeColor = 'rgb(0,102,212)'; //this.secondaryColor;
        reAxis.fillColor = 'rgb(0,102,212)'; //this.secondaryColor;
        reAxis.strokeWidth = 0.5;


        const imStart = new this.options.paperScope.Point(x / this.options.devicePixelRatio + this.options.LEFT, this.options.TOP);
        const imEnd = new this.options.paperScope.Point(x / this.options.devicePixelRatio + this.options.LEFT, this.options.TOP + this.options.figure.height);

        const imAxis = new this.options.paperScope.Path({ segments: [imStart, imEnd] });
        imAxis.strokeColor = 'rgb(0,102,212)'; //this.secondaryColor;
        imAxis.fillColor = 'rgb(0,102,212)'; //this.secondaryColor;
        imAxis.strokeWidth = 0.5;

        const path2 = new this.options.paperScope.Path.Rectangle({
            from: [this.options.LEFT, this.options.TOP],
            to: [this.options.figure.width + this.options.LEFT, this.options.figure.height + this.options.TOP],
        });

        const group = new this.options.paperScope.Group(path2);

        group.style = {
            strokeColor: this.secondaryColor,
            // dashArray: [4, 10],
            strokeWidth: 3,
            strokeCap: 'round',
        };
    }
}
