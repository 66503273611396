import React, { useCallback } from 'react';
import { debounce } from 'lodash';

interface InputWithDProps {
  label: string;
  value: number;
  dValue: number;
  onChange: (value: number) => void;
  onDChange: (value: number) => void;
}

const InputWithD: React.FC<InputWithDProps> = ({ label, value, dValue, onChange, onDChange }) => {
    return (
      <div className="input-group">
        <div className="input-group-prepend">
            <label className="btn btn-secondary">
              {label}
            </label>
          </div>
        <input className="form-control" defaultValue={value} type="text" onChange={e => onChange(parseFloat(e.target.value))} />
        <div className="input-group-prepend">
          <span className="input-group-text">&Delta;</span>
        </div>
        <input className="form-control" defaultValue={dValue} type="text" onChange={e => onDChange(parseFloat(e.target.value))} />
      </div>
    );
  };

export default InputWithD;