// @ts-nocheck

export type FigureOptions = {
    width: number;
    height: number;
    scale: boolean;
    padding?: number;
    zoom?: number;
}


abstract class Figure<AlgorithmOptions> {
    public xValues: number[];
    public yValues: number[];

    private minX: number;
    private minY: number;
    private maxX: number;
    private maxY: number;
    private distanceX: number;
    private distanceY: number;
    private scaledWidth: number;
    private scaledHeight: number;
    private origo: { x: number; y: number };

    constructor(protected options: FigureOptions & { algorithmOptions: AlgorithmOptions }) {
        this.options.zoom = 1;
        this.options.padding = 20;
        this.initialize();
    }

    public get width(){
        return this.options.width;
    }

    public get height(){
        return this.options.height;
    }

    initialize() {
        this.generateDots();
        this.generateExtremas();
        this.calculateScaledDimensions();
        this.origo = this.calculateDotPosition(0, 0);
    }

    generateExtremas() {
        /*
        this.minX = -2 * (10 ** 98);//Math.min.apply(Math, this.xValues);
        this.minY = -2 * (10 ** 98);//Math.min.apply(Math, this.yValues);

        this.maxX = 2 * (10 ** 98);//Math.max.apply(Math, this.xValues);
        this.maxY = 1 * (10 ** 98);
        *
        * */


        this.minX = Math.min.apply(Math, this.xValues);
        this.minY = Math.min.apply(Math, this.yValues);

        this.maxX = Math.max.apply(Math, this.xValues);
        this.maxY = Math.max.apply(Math, this.yValues);
    }

    calculateScaledDimensions() {
        if (this.minX < 0) {
            this.distanceX = this.maxX + Math.abs(this.minX);
        } else {
            this.distanceX = this.maxX + this.minX;
        }

        if (this.minY < 0) {
            this.distanceY = this.maxY + Math.abs(this.minY);
        } else {
            this.distanceY = this.maxY + this.minY;
        }

        const scaleH = (this.distanceY / this.distanceX);
        const scaleW = (this.distanceX / this.distanceY);

        this.scaledWidth = this.options.width;
        if (scaleW < 1) {
            this.scaledWidth *= scaleW;
        }

        this.scaledHeight = this.options.height;
        if (scaleH < 1) {
            this.scaledHeight *= scaleH;
        }
    }

    calculateDotPosition(xValue: number, yValue: number) {
        let x = xValue * this.options.zoom;
        let y = yValue * this.options.zoom;

        x += Math.abs(this.minX);
        y += Math.abs(this.minY);

        x = (x / this.distanceX) * ((this.options.scale ? this.scaledWidth : this.options.width) - this.options.padding);
        y = (y / this.distanceY) * ((this.options.scale ? this.scaledHeight : this.options.height) - this.options.padding);

        y = ((this.options.scale ? this.scaledHeight : this.options.height) - this.options.padding) - y;
        return { x: x + this.options.padding / 2, y: y + this.options.padding / 2 };
    }

    calculateDotPositions(): [number, number][] {
        return this.xValues.map((xValue, key) => {
            const yValue = this.yValues[key];
            const { x, y } = this.calculateDotPosition(xValue, yValue);
            return [x, y];
        });
    }

    abstract generateDots(): void;
}


export default Figure;
