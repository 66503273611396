// @ts-nocheck

import iterate, { Algorithm, AlgorithmParameters } from "./algorithms";

import Figure from './figure';

export type MainFigureAlgorithmOptions = AlgorithmParameters & {
    iterations: number;
    algorithm: Algorithm;
}

class MainFigure extends Figure<MainFigureAlgorithmOptions> {
    generateDots() {
        console.log('ITERATENOW',this.options.algorithmOptions.g, this.options.algorithmOptions.v)
        const values = iterate(
            this.options.algorithmOptions.iterations,
            this.options.algorithmOptions.algorithm,
            {
                Z: this.options.algorithmOptions.Z,
                C: this.options.algorithmOptions.C,
                exp: this.options.algorithmOptions.exp,
                beta: this.options.algorithmOptions.beta,
                g: this.options.algorithmOptions.g,
                v: this.options.algorithmOptions.v,
                lambda: this.options.algorithmOptions.lambda,
            }
        );

        this.xValues = values.reValues;
        this.yValues = values.imValues;
    }
}

export default MainFigure;
