// @ts-nocheck

import './Figure.scss'

import { algorithms } from '../lib/algorithms'
import { complex } from 'mathjs';

import Frame from '../lib/frame';
import MainFigure from '../lib/mainFigure';
import { useEffect, useState } from "react";
import { showNumber, stringToForm, formToString } from '../lib/utilities';

import Number from './Number';

function showCanvas({zoomLevel, canvasId, showLines, lineStrength, dotStrength, dotSize, exp, algorithm, reC, imC, beta, g, v, lambda, n }) {
    const canvas = document.getElementById(canvasId);

    const _width =  100 + zoomLevel * 10;
    const _height = 100 + zoomLevel * 10;
    // @ts-ignore
    canvas.setAttribute('width', _width);
    // @ts-ignore
    canvas.setAttribute('height', _height);
    const options = {
        lines: showLines,
        exp,
        size: dotSize,
        lineStrength,
        dotStrength,
        flow: false,
        alg: algorithm,
        Z: complex(0, 0),
        C: complex(reC, imC),
        iterations: n,
        beta,
        g,
        v,
        lambda,
    }

    const frame = new Frame({
        width: _width,
        canvas,
        height: (_width),
        lines: options.lines,
        size: options.size,
        lineStrength: options.lineStrength,
        dotStrength: options.dotStrength,
        flow: options.flow,
        algorithmOptions: {
            // @ts-ignore
            algorithm: algorithms[options.alg],
            Z: options.Z,
            C: options.C,
            exp: options.exp,
            iterations: options.iterations,
            beta: options.beta,
            g: options.g,
            v: options.v,
            lambda: options.lambda,
        },
    });

    return frame.createImage(MainFigure);
}

function Figure({zoomLevel, showParams, showIterations, showLines, lineStrength, dotStrength, dotSize, exp, algorithm, reC, imC, beta, g, v, lambda, n }) {
    const canvasId = 'myCanvas-'+Math.random();

    const [xLabel, setXLabel] = useState('myXLabel')
    const [yLabel, setYLabel] = useState('myYLabel')


    const [xSuffix, setXSuffix] = useState('i')
    const [ySuffix, setYSuffix] = useState('i')

    const [mainFigure, setMainFigure] = useState(null);


    useEffect(() => {
        const figures = showCanvas({zoomLevel, canvasId, showLines, showIterations, lineStrength, dotStrength, dotSize, exp, algorithm, reC, imC, beta, g, v, lambda, n })
        if (figures.mainFigure) {
            setMainFigure(figures.mainFigure)
        }
    }, [zoomLevel, showLines, showIterations, lineStrength, dotStrength, dotSize, exp, algorithm, reC, imC, beta, g, v, lambda, n])


    const XLabel = xLabel ? <div className="value xLabel">{xLabel}</div> : null;
    const YLabel = yLabel ? <div className="value yLabel">{yLabel}</div> : null;

    let coords;
    if (mainFigure) {
        coords = <>
            <div className="value minReal"><Number number={mainFigure.minX} method="toExponential" decimals={2}/></div>
            <div className="value minImag"><Number number={mainFigure.minY} method="toExponential" decimals={2} suffix={ySuffix}/></div>
            <div className="value maxReal"><Number number={mainFigure.maxX} method="toExponential" decimals={2}/></div>
            <div className="value maxImag"><Number number={mainFigure.maxY} method="toExponential" decimals={2} suffix={ySuffix}/></div>
        </>
    }


    return (
        <div className={`figure-box ${showParams? 'show-params': ''}`}>
            <canvas id={canvasId} width={ 100 + zoomLevel * 10} height={ 100 + zoomLevel * 10}></canvas>
            {showParams && (<ul class="params-panel">
                <li>
                    <span class="parameter-name">EXP</span> <span class="parameter-value"><Number number={exp} method="toFixed" /></span>
                </li>
                {algorithm === 'MX' ? (<><li>
                    <span class="parameter-name">&beta;</span> <span class="parameter-value"><Number number={beta} method="toPrecision" /></span>
                </li>
                <li>
                    <span class="parameter-name">g</span> <span class="parameter-value"><Number number={g} method="toPrecision"/></span>
                </li> 
                <li>
                    <span class="parameter-name">v</span> <span class="parameter-value"><Number number={v} method="toPrecision" /></span>
                </li>                      
                <li>
                    <span class="parameter-name">&lambda;</span> <span class="parameter-value"><Number number={lambda} method="toPrecision" /></span>
                </li></>) : (<><li>
                    <span class="parameter-name">Re(C)</span> <span class="parameter-value"><Number number={reC} method="toFixed" /></span>
                </li>
                <li>
                    <span class="parameter-name">Im(C)</span> <span class="parameter-value"><Number number={imC} method="toFixed" suffix="i"/></span>
                </li>
                <li>
                    <span class="parameter-name">Re(Z<sub>0</sub>)</span> <input class="z-real" value="0" />
                </li>                         
                <li>
                    <span class="parameter-name">ROT</span> <span class="parameter-value"><Number number={-1 * reC * exp} method="toFixed" /></span>
                </li></>)}  
                <li>
                    <span class="parameter-name">n</span> <span class="parameter-value"><Number number={n} method="toFixed" /></span>
                </li>
            </ul>)}
            <div className="coords-container">
                <div className="coord-values">
                    {/* {XLabel} */}
                    {/* {YLabel} */}
                    {coords}
                </div>
            </div>
        </div>
    )
}

export default Figure;
