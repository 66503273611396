// @ts-nocheck

import MainFigure, { MainFigureAlgorithmOptions } from './mainFigure';
import Canvas from './canvas';
import Figure from './figure';

const paper = require('paper');

declare type FrameOptions = {
    canvas: any;
    lines: boolean;
    size: number;
    lineStrength: number;
    dotStrength: number;
    width: number;
    height: number;
    flow: boolean;
    algorithmOptions: MainFigureAlgorithmOptions;
}

export default class Frame {
    private paperScope;

    constructor(private options: FrameOptions) {
    }

    public createImage(FigureConstructor, scale = true) {

        const mainFigure = new FigureConstructor({
            width: this.options.width,
            height: this.options.height,
            scale: scale,
            algorithmOptions: this.options.algorithmOptions,
        });

        const figures = { mainFigure };

        console.log(this.options.canvas)
        this.paperScope = paper.setup(this.options.canvas);

        const figureCanvas = new Canvas({
            paperScope: this.paperScope,
            size: this.options.size,
            dotStrength: this.options.dotStrength,
            lineStrength: this.options.lineStrength,
            figure: mainFigure,
            TOP: 0,
        });

        // figureCanvas.addText('hello', 'center', figureCanvas.options.figure.width / 2, 16);

        figureCanvas.addDots();
        if (this.options.lines) {
            figureCanvas.addLines();
        }
        figureCanvas.showAxis();

        this.paperScope.view.draw();

        return figures;
    }

    addFlow({
                dots, TOP, LEFT, WIDTH, HEIGHT, size,
            }) {
        class FlowFigure extends Figure<{ dots: number[] }> {
            generateDots() {
                this.xValues = [];
                this.yValues = [];
                this.options.algorithmOptions.dots.forEach((dot, key) => {
                    this.xValues.push(key + 1);
                    this.yValues.push(dot);
                });
            }
        }

        const flowFigure = new FlowFigure({
            width: WIDTH,
            height: HEIGHT,
            scale: false,
            algorithmOptions: {
                dots,
            },
        });

        const flowCanvas = new Canvas({
            figure: flowFigure,
            dotStrength: this.options.dotStrength,
            lineStrength: this.options.lineStrength,
            paperScope: this.paperScope,
            LEFT,
            TOP,
            size,
        });

        flowCanvas.addDots();
        flowCanvas.showAxis();

        return flowFigure;
    }


    downloadSVG(paperScope) {
        const fileName = 'paperjs_example.svg';
        const cucc = paperScope.project.exportSVG({ asString: true });
        const url = `data:image/svg+xml;utf8,${encodeURIComponent(cucc)}`;

        const link = document.createElement('a');
        link.download = fileName;
        link.href = url;
        link.click();
    }

};
