import { add, multiply, complex, pow, subtract, divide } from 'mathjs';
import { Complex } from 'mathjs';

type ValueOf<T> = T[keyof T];

export type AlgorithmParameters = { Z: Complex; C: Complex; exp: number };
export type CreatorParameters = { Z: Complex, exp: number, beta: number, g: number, v: number, lambda: number };

export const algorithms = {
    MA({ Z, C, exp }: AlgorithmParameters): Complex {
        return add(pow(Z, exp), C) as Complex;
    },

    MB({ Z, C, exp }: AlgorithmParameters): Complex {
        return pow(add(pow(Z, 1 / exp), C), exp) as Complex;
    },

    MC({ Z, C, exp }: AlgorithmParameters): Complex {
        return pow(add(pow(multiply(Z, C), 1 / exp), C), exp) as Complex;
    },
    MX({ Z, exp = 2, beta = -0.0065, g = 4, v = 5, lambda = 1.009 }: CreatorParameters): Complex {
        const felso = complex(beta, 2 * Math.PI * g / v)
        const ebetus = pow(Math.E, felso);
        const kettes = divide(ebetus, 2);
        return add(pow(Z, exp), multiply(subtract(kettes, pow(kettes, 2)), lambda)) as Complex;
    }
};

export type Algorithm = ValueOf<typeof algorithms>;
export type IterationResult = {
    reValues: number[];
    imValues: number[];
};
export type ComplexNumber = { re: number; im: number };
export type IterationOptions = {
    Z: ComplexNumber;
    C: ComplexNumber;
    exp: number;
    beta: number;
    g: number;
    v: number;
    lambda: number;    
};

export default function iterate(iterations: number, algorithm: Algorithm, options: IterationOptions): IterationResult {
    const values: IterationResult = { reValues: [], imValues: [] };

    let Z: Complex = complex(options.Z.re, options.Z.im);
    const C: Complex = complex(options.C.re, options.C.im);

    for (let n: number = 0; n < iterations; n += 1) {
        // @ts-ignore
        Z = algorithm({
            Z,
            C,
            exp: options.exp,
            beta: options.beta,
            g: options.g,
            v: options.v,
            lambda: options.lambda,

        });

        if (isNaN(Z.im) || isNaN(Z.re)) {
            break;
        }
        values.reValues.push(Z.re);
        values.imValues.push(Z.im);
    }

    return values;
}
