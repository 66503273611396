import './Number.scss'

export default function Number({ number, method = 'toFixed', decimals = 4, suffix = '' }) {
    if (!number) {
        return <></>
    }
    if (String(number.toPrecision(4)).length > 8) {
        // @ts-ignore
        if (Math.abs(Number(number.toPrecision(4)) - number) < 0.000001) {
            return <span>{String(Number(number.toPrecision(4)))}</span>;
        }
        return <span>{number.toPrecision(4)}{suffix}</span>
    }
    return <span className="number">{number[method](decimals)}{suffix}<span
        className="long-number">{number}{suffix}</span></span>
}
